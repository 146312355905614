<template>
  <b-modal
    v-model="data.showModal"
    size="lg"
    button-size="sm"
    modal-class="template-modal"
    title-class="w-full"
    footer-class="w-full"
    no-fade
    @show="onShow"
    @hide="onHide"
    hide-header-close
  >
    <!-- Modal header -->
    <div slot="modal-title">
      <div class="flex w-full justify-between">
        New candle
      </div>
    </div>

    <!-- Modal content -->
    <div class="flex flex-col gap-2">
      <table class="table table-google table-highlight" v-if="candle">
        <tbody>
          <tr>
            <td class="row-fit">
              <span :class="{ 'candle-issue': candleIssues.includes('candle_date') }">
                Date
              </span>
            </td>
            <td>
              <div class="flex justify-end">
                <date-picker
                  class="date-picker"
                  v-model="candle.candle_date"
                  format="DD.MM.YYYY"
                  type="date"
                  valueType="YYYY-MM-DD"
                  :clearable="false"
                  :lang="datepickerLocale"
                >
                </date-picker>
              </div>
            </td>
          </tr>

          <tr>
            <td class="row-fit">
              <span :class="{ 'candle-issue': candleIssues.includes('brand_id') }">
                Brand
              </span>
            </td>
            <td>
              <div>
                <v-select
                  v-model="candle.brand_id"
                  :options="dropdowns.candle_brand"
                  size="sm"
                  label="text"
                  :reduce="option => option.value"
                  class="w-100"
                />
              </div>
            </td>
          </tr>

          <tr>
            <td class="row-fit">
              <span :class="{ 'candle-issue': candleIssues.includes('glass_id') }">
                Glass
              </span>
            </td>
            <td>
              <div>
                <v-select
                  v-model="candle.glass_id"
                  :options="dropdowns.glass"
                  size="sm"
                  label="text"
                  :reduce="option => option.value"
                  class="w-100"
                />
                <option-note :id="candle.glass_id" />
              </div>
            </td>
          </tr>

          <tr>
            <td class="row-fit">
              <span :class="{ 'candle-issue': candleIssues.includes('wax_id') }">
                Wax
              </span>
            </td>
            <td>
              <div>
                <v-select
                  v-model="candle.wax_id"
                  :options="dropdowns.wax"
                  size="sm"
                  label="text"
                  :reduce="option => option.value"
                  class="w-100"
                />
              </div>
            </td>
          </tr>

          <tr>
            <td class="row-fit">
              <span :class="{ 'candle-issue': candleIssues.includes('wick_id') }">
                Wick
              </span>
            </td>
            <td>
              <div>
                <v-select
                  v-model="candle.wick_id"
                  :options="dropdowns.wick"
                  size="sm"
                  label="text"
                  :reduce="option => option.value"
                  class="w-100"
                />
              </div>
            </td>
          </tr>

          <tr>
            <td class="row-fit text-nowrap">
              <span :class="{ 'candle-issue': candleIssues.includes('wick_count') }">
                Wick Count
              </span>
            </td>
            <td>
              <div>
                <b-form-input
                  v-model="candle.wick_count"
                  type="number"
                  size="sm"
                  min="0"
                  max="10"
                  autocomplete="off"
                ></b-form-input>
              </div>
            </td>
          </tr>

          <tr>
            <td class="row-fit">
              <span :class="{ 'candle-issue': candleIssues.includes('additive') }">
                Additives
              </span>
            </td>
            <td>
              <div class="flex flex-col gap-2">
                <div v-for="(additive, index) in candle.additive" :key="`additive_${index}`">
                  <div class="flex gap-2 items-center">
                    <v-select
                      v-model="additive.name_id"
                      :options="dropdowns.additive"
                      size="sm"
                      label="text"
                      :reduce="option => option.value"
                      :class="
                        (additive.name_id !== null && additive.name_id !== 0)
                          ? 'w-8/12'
                          : 'w-100'
                      "
                    />
                    <v-select
                      v-if="additive.name_id !== null && additive.name_id !== 0"
                      v-model="additive.pct_id"
                      :options="dropdowns.additive_pct"
                      size="sm"
                      label="text"
                      :reduce="option => option.value"
                      class="w-4/12"
                    />
                    <b-btn
                      size="sm"
                      variant="danger"
                      @click="deleteExtra('additive', index)"
                      title="Delete"
                    >
                      &#x2715;
                    </b-btn>
                  </div>
                  <option-note :id="additive.name_id" />
                </div>
                <div>
                  <b-btn
                    size="sm"
                    variant="link"
                    @click="addExtra('additive')"
                    class="pl-0"
                  >
                    Add additive
                  </b-btn>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td class="row-fit">
              <span :class="{ 'candle-issue': candleIssues.includes('dye') }">
                Dyes
              </span>
            </td>
            <td>
              <div class="flex flex-col gap-2">
                <div v-for="(dye, index) in candle.dye" :key="`dye_${index}`">
                  <div class="flex gap-2 items-center">
                    <v-select
                      v-model="dye.name_id"
                      :options="dropdowns.dye"
                      size="sm"
                      label="text"
                      :reduce="option => option.value"
                      class="w-100"
                    />
                    <b-btn
                      size="sm"
                      variant="danger"
                      @click="deleteExtra('dye', index)"
                      title="Delete"
                    >
                      &#x2715;
                    </b-btn>
                  </div>
                  <option-note :id="dye.name_id" />
                </div>
                <div>
                  <b-btn
                    size="sm"
                    variant="link"
                    @click="addExtra('dye')"
                    class="pl-0"
                  >
                    Add dye
                  </b-btn>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td class="row-fit">
              <span :class="{ 'candle-issue': candleIssues.includes('fragrance') }">
                Fragrances
              </span>
            </td>
            <td>
              <div class="flex flex-col gap-2">
                <div v-for="(fragrance, index) in candle.fragrance" :key="`fragrance_${index}`">
                  <div class="flex gap-2 items-center">
                    <v-select
                      v-model="fragrance.name_id"
                      :options="dropdowns.fragrance"
                      size="sm"
                      label="text"
                      :reduce="option => option.value"
                      :class="
                        (fragrance.name_id !== null && fragrance.name_id !== 0)
                          ? 'w-8/12'
                          : 'w-100'
                      "
                    />
                    <v-select
                      v-if="fragrance.name_id !== null && fragrance.name_id !== 0"
                      v-model="fragrance.pct_id"
                      :options="dropdowns.fragrance_pct"
                      size="sm"
                      label="text"
                      :reduce="option => option.value"
                      class="w-4/12"
                    />
                    <b-btn
                      size="sm"
                      variant="danger"
                      @click="deleteExtra('fragrance', index)"
                      title="Delete"
                    >
                      &#x2715;
                    </b-btn>
                  </div>
                  <option-note :id="fragrance.name_id" />
                </div>
                <div>
                  <b-btn
                    size="sm"
                    variant="link"
                    @click="addExtra('fragrance')"
                    class="pl-0"
                  >
                    Add fragrance
                  </b-btn>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal footer -->
    <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
      <div class="flex w-full justify-content-between">
        <div>
          <b-btn
            size="sm"
            variant="primary"
            :disabled="isLoading || !canSave"
            @click="onSave"
          >
            Create
          </b-btn>
        </div>
        <div class="flex gap-4 items-center">
          <printer-select />

          <b-form-checkbox v-model="printLabel">
            Print label
          </b-form-checkbox>

          <b-form-checkbox v-model="keepData">
            Keep data
          </b-form-checkbox>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment';
import {
  datepickerLocale,
  getLabelPrinter,
} from '@/helpers';
import { getCandleIssues, isCandleValid } from '@/composables/candle';

const OptionNote = () => import('@/components/select/OptionNote.vue');
const PrinterSelect = () => import('@/components/labels/PrinterSelect.vue');

export default {
  name: 'CreateModal',
  components: {
    OptionNote,
    PrinterSelect,
  },
  props: {
    data: Object,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    candleIssues() {
      return getCandleIssues(this.candle);
    },
    canSave() {
      return isCandleValid(this.candle);
    },
    preventMessage() {
      return 'You have unsaved changes. Are you sure you want to close?';
    },
    preventClose() {
      return false;
    },
    dropdowns() {
      return this.$store.state.app.dropdowns;
    },
    datepickerLocale() {
      return datepickerLocale;
    },
  },
  data() {
    return {
      loadingCount: 0,
      filters: {},
      options: {},
      candle: null,
      printLabel: false,
      keepData: false,
    };
  },
  methods: {
    resetCandle() {
      this.candle = {
        candle_reference: '',
        candle_date: moment().format('YYYY-MM-DD'),
        candle_status: 'testing',
        brand_id: null,
        glass_id: null,
        wax_id: null,
        wick_id: null,
        wick_count: 1,
        additive: [
          { id: null, name_id: null, pct_id: null },
        ],
        dye: [
          { id: null, name_id: null, pct_id: null },
        ],
        fragrance: [
          { id: null, name_id: null, pct_id: null },
        ],
      };
    },
    fillSource() {
      if (this.data.source) {
        this.candle = {
          ...this.candle,
          ...this.data.source,
        };
      }
    },
    addExtra(extraField) {
      this.candle[extraField].push({
        id: null,
        name_id: null,
        pct_id: null,
      });
    },
    deleteExtra(extraField, index) {
      this.candle[extraField].splice(index, 1);
    },
    fetchAll() {
      // Implement logic
    },
    handleLabel(candle) {
      if (!this.printLabel) {
        return;
      }
      this.loadingCount++;
      this.$http
        .post(`/candle/${candle.id}/print_link`)
        .send({ printer_id: getLabelPrinter() })
        .then(() => {
          // no-op
        })
        .catch((err) => {
          this.$toast.error(`Failed to print: ${err.response.text}`);
        });
    },
    onSave() {
      this.loadingCount++;
      this.$http
        .post('/candle')
        .send({ candle: this.candle })
        .then((res) => {
          this.handleLabel(res.body);
          this.$emit('created', res.body, !this.keepData);
          if (!this.keepData) {
            this.onClose();
          }
        })
        .catch((err) => {
          this.$toast.error(`Failed to create candle: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onShow() {
      this.resetCandle();
      this.fillSource();
      this.fetchAll();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
    onHide(event) {
      if (this.preventClose && !confirm(this.preventMessage)) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss">
</style>
